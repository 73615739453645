<template>
    <section>
        <GoBack />
        <div class="page-vertically-centered">
            <div class="is-flex is-justify-content-center">
                <div class="rnd-container mt-4 mb-6">
                    <b-icon icon="lock" size="is-large"></b-icon>
                </div>
            </div>

            <div class="justify-center">
                <p class="is-blue page-title mb-2">This page is password protected</p>
                <p class="is-light-purple is-size-9 is-flex is-justify-content-center mb-6">
                    Please enter the guider registration password we sent you to sign up as a guider.
                </p>
            </div>

            <b-input class="mb-5" icon="lock" icon-pack="fas" v-model="unlockPassword" autocomplete="current-password"
                type="password"  placeholder="Enter your guider registration code" password-reveal>
            </b-input>

            <b-button :loading="isLoading" @click.prevent="validatePassword()" class="mb-5" type="is-primary" expanded>
                Next
            </b-button>

            <p class="is-light-purple is-size-9 justify-center mb-6">
                Don’t have a password? No problem! <router-link :to="{name: 'registration'}"><a class="link">Request to join</a></router-link> to get a registration password.
            </p>
        </div>
    </section>
</template>

<script>
import { db } from '../firebase';
import GoBack from '../components/elements/GoBack.vue';
import { GuidersMixin } from '../mixins';
/* import { mapMutations, mapState } from 'vuex';
import { UsersMutations, UsersStates } from '../store/Storetypes'; */
export default {
    mixins: [GuidersMixin],
    data() {
        return {
            isLoading: false,
            codes: [],
            unlockPassword: null
        }
    },
    components: {
        GoBack
    },
    created() {
        db.firestore().collection("codes").get().then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
                this.codes.push(doc.data().code);
            });
        });
    },
   /*  computed: {
        ...mapState({
            unlockPassword: state => state.users[UsersStates.UNLOCK_PASSWORD]
        })
    }, */
    methods: {
       /*  ...mapMutations({
            setUnlockPassword: UsersMutations.SET_UNLOCK_PASSWORD
        }), */
        async validatePassword() {
            const passwordIndex = this.codes.findIndex((code) => code === this.unlockPassword)
            const passwordsMatch = passwordIndex > 0
            this.$emit('onPasswordValidated', passwordsMatch)
            if (!passwordsMatch) {
                this.$buefy.toast.open({
                    duration: 10000,
                    message: "Invalid password.",
                    position: "is-bottom",
                    type: "is-danger",
                });
                return
            }
            db.firestore()
                .collection("codes")
                .doc(this.unlockPassword)
                .delete().catch((error)=> {
                   console.log(error);
                })
              //  this.setUnlockPassword(null)
        }
    },
  /*   beforeDestroy(){
        this.setUnlockPassword(this.unlockPassword)
    } */
}
</script>

<style lang="scss" scoped>
.justify-center {
    text-align: center;
}

.rnd-container {
    background-color: #F8F7FA;
    border-radius: 50%;
    padding: 20px;
    height: auto;
    width: fit-content;
}
.page-vertically-centered {
    position: relative;
    top: 10vh;
}
</style>